<!-- 发票管理 -->
<template>
  <div class="page">
    <!-- 外层标签 -->
    <ul class="nav topNavDiv">
      <li v-for="(item, index) in exNav" :key="index" :class="{ active: queryFrom.invoice_type == item.index, finger: true }" @click="clickNav(item.index, 1)">
        {{ item.name }}
        <div class="linear" v-if="queryFrom.invoice_type == item.index"></div>
      </li>
    </ul>
    <!-- 主体 -->
    <div class="pagePadding">
      <!-- 表单 -->
      <Form inline class="formMarginBtm20">
        <!-- 发票单号 -->
        <FormItem>
          <span>发票单号：</span>
          <Input placeholder="请输入" v-model="queryFrom.invoice_oddnum" class="iviewIptWidth250" />
        </FormItem>
        <!-- 供应商 -->
        <FormItem class="marginLeft40" v-if="queryFrom.invoice_type == '1'">
          <span>供应商：</span>
          <Select placeholder="请选择" v-model="queryFrom.name" clearable filterable class="iviewIptWidth250">
            <Option v-for="(item, index) in thirdList" :value="item.name + ''" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <!-- 客户名称 -->
        <FormItem class="marginLeft40" v-else>
          <span>客户名称：</span>
          <Select placeholder="请选择" v-model="queryFrom.name" clearable filterable class="iviewIptWidth250">
            <Option v-for="(item, index) in thirdList" :value="item.customer_name + ''" :key="index">{{ item.customer_name }}</Option>
          </Select>
        </FormItem>
        <!-- 开票时间 -->
        <FormItem class="marginLeft40 marginRight40">
          <span>开票时间：</span>
          <template>
            <DatePicker clearable placeholder="请选择" format="yyyy-MM-dd" :value="queryFrom.billing_time_start" :editable="false" @on-change="changeTime($event, 1)" class="iviewIptWidth250"></DatePicker>
            <span style="padding: 0 10px;">至</span>
            <DatePicker clearable placeholder="请选择" format="yyyy-MM-dd" :value="queryFrom.billing_time_end" :options="options" :editable="false" @on-change="changeTime($event, 2)" class="iviewIptWidth250"></DatePicker>
          </template>
        </FormItem>
        <!-- 按钮 -->
        <FormItem class="po-create-number marginRight0" :label-width="10">
          <span class="pageBtn finger btnSure" @click="query">查询</span>
          <!-- <span class="pageBtn finger btnReset marginLeft20" @click="addNew('')">新增1</span> -->
          <span class="pageBtn finger btnReset marginLeft20" @click="addNew('请选择客户')" v-if="queryFrom.invoice_type === '2'">新增</span>
          <span class="pageBtn finger btnReset marginLeft20" @click="addNew('请选择供应商')" v-else>新增</span>
        </FormItem>
      </Form>
      <!-- 表格 -->
      <div class="table ≈">
        <Table :productList="listColumns" :productData="listData" :total="total" :pages="pages" @change-page="changePage" :isLoad="isLoad" :loading="isLoad" totalText="条记录">
          <!-- <template slot-scope="{ row,index }" slot="jujiao">
          <div class="changeClass">
            <div v-show="row.jujiao" @click="clickBeiZhu(index, row.oddnum, row.oddnum)">
              {{ row.oddnum ? row.oddnum : '-' }}
            </div>
            <div v-if="!row.jujiao">
              <Input placeholder="请输入" :ref="'input' + row.oddnum" v-model.trim="changeNote" @on-blur="changeNoteFun(row)" :autofocus="1 == 1"></Input>
            </div>
          </div>
          </template> -->
          <div slot="summary" class="summary" v-if="listData && listData.length > 0">
            合计金额：<span class="color389">{{ $utils.formatMoney(totleMoney, true) }}</span>
          </div>
        </Table>
      </div>
    </div>
    <!-- 反冲确认-弹窗 -->
    <Modal v-model="isConfirm[0]" label-position="left" width="400" :footer-hide="true" :mask-closable="false" :closable="false" class-name="vertical-center-modal">
      <div class="topModal clearfix">
        <img src="@/assets/images/tipsPostImg.png" class="tipsImg fl" />
        <div class="flDiv fl">
          <p>反冲</p>
          <p>是否确认反冲</p>
        </div>
      </div>
      <div class="foot">
        <span class="pageBtn finger btnCancle" @click="isConfirm = [false, undefined]">取消</span>
        <span class="pageBtn finger marginLeft20 btnSure" @click="recoil(isConfirm[1])">确定</span>
      </div>
    </Modal>
    <Modal v-model="choiseCompany" :title="choiseTitle" label-position="left" width="400" :footer-hide="true" :mask-closable="false" :closable="false" class-name="vertical-center-modal">
      <Select placeholder="请选择" v-if="this.queryFrom.invoice_type == 1" v-model="choiseCompanyName" clearable filterable class="iptWidth">
        <Option v-for="(item, index) in choiseList" :key="index" :value="item.id">{{ item.name }}</Option>
      </Select>
      <Select placeholder="请选择" v-else v-model="choiseCompanyName" clearable filterable class="iptWidth">
        <Option v-for="(item, index) in choiseList" :key="index" :value="item.customer_name">{{ item.customer_name }}</Option>
      </Select>
      <div class="foot">
        <span class="pageBtn finger btnCancle" @click="choiseCompany = false">取消</span>
        <span class="pageBtn finger marginLeft20 btnSure" @click="nextChoise">下一步</span>
      </div>
    </Modal>
  </div>
</template>

<script>
import Table from '@/components/table'

export default {
  name: 'invoiceManage',
  components: {
    Table,
  },
  data() {
    return {
      choiseList: [],
      choiseCompanyName: '',
      choiseTitle: '',
      choiseCompany: false,
      listDataIndex: null,
      listDataNote: '',
      changeNote: {},
      rowOddnum: '',
      // 反冲确认弹窗 + 发票id
      isConfirm: [false, ''],
      isLoad: true,
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 75,
          maxWidth: 90,
        },
        {
          title: '发票单号',
          key: 'oddnum',
          align: 'center',
          minWidth: 200,
        },
        {
          key: 'name',
          align: 'center',
          minWidth: 200,
          renderHeader: (h, param) => {
            let str = ''
            if (this.queryFrom.invoice_type == '1') {
              str = '供应商'
            } else {
              str = '客户名称'
            }
            return h('span', {}, str)
          },
        },
        {
          title: '发票金额',
          align: 'center',
          minWidth: 150,
          render: (h, param) => {
            let str = ''
            if (param.row.money == null) {
              str = ''
            } else {
              str = this.$utils.formatMoney(param.row.money, true)
            }
            return h('span', str)
          },
        },
        {
          title: '开票时间',
          key: 'time',
          minWidth: 150,
          align: 'center',
          render: (h, param) => {
            return h('div', [h('span', {}, param.row.time ? this.$moment(param.row.time * 1000).format('YYYY-MM-DD') : '')])
          },
        },
        //  {
        //   title: '备注',
        //   slot: 'jujiao',
        //   minWidth: 350,
        //   align: 'center',
        // },
        {
          title: '备注',
          key: 'note',
          minWidth: 350,
          align: 'center',
        },
        {
          title: '操作',
          align: 'center',
          minWidth: 150,
          render: (h, param) => {
            if (this.queryFrom.invoice_type == 1) {
              return h('div', [
                h(
                  'span',
                  {
                    class: 'tableFont',
                    on: {
                      click: () => {
                        this.$router.push({
                          path: '/lookListTable',
                          query: {
                            invoice_id: param.row.invoice_id,
                          },
                        })
                      },
                    },
                  },
                  '查看'
                ),
              ])
            } else {
              return h('div', [
                h(
                  'span',
                  {
                    class: 'tableFont',
                    on: {
                      click: () => {
                        this.$router.push({
                          path: '/lookListTableKehu',
                          query: {
                            invoice_id: param.row.invoice_id,
                            customer_id: param.row.customer_id,
                            customer_type: param.row.customer_type,
                          },
                        })
                      },
                    },
                  },
                  '查看'
                ),
              ])
            }
          },
        },
      ],
      // 表单拉下选项
      thirdList: [],
      gongyingshangList: [],
      kehuList: [],
      // 查询参数
      queryFrom: {
        invoice_oddnum: '',
        name: '',
        billing_time_start: '',
        billing_time_end: '',
        invoice_type: '1',
        status: '',
        documents: '',
      },
      // 页码
      pages: {
        page: 1,
        rows: 10,
      },
      // 总条数
      total: 1,
      // 合计金额
      totleMoney: 0,
      // 内层标签
      inNav: [
        {
          name: '全部',
          index: '',
        },
        {
          name: '已关联',
          index: '1',
        },
        {
          name: '未关联',
          index: '0',
        },
      ],
      // 外层标签
      exNav: [
        {
          name: '供应商发票',
          index: '1',
        },
        {
          name: '客户发票',
          index: '2',
        },
      ],
      options: {},
    }
  },
  async activated() {
    if (sessionStorage.getItem('updataCache') == 0) {
      await this.getInvoiceManage()
      sessionStorage.setItem('updataCache', '1')
    }
  },
  mounted() {
    if (this.$route.params.index) {
      this.queryFrom.invoice_type = this.$route.params.index
    }
    this.getInvoiceManage()
    this.clickNav(this.$route.query.clickType || 1, 1)
  },
  methods: {
    getDetail(id) {
      this.$http.get(this.$api.supplierInvoiceDetail, { invoice_id: id }, true).then(res => {})
    },
    nextChoise() {
      if (!this.choiseCompanyName && this.queryFrom.invoice_type == 1) {
        return this.$Message.warning(`请选择供应商`)
      } else if (!this.choiseCompanyName && this.queryFrom.invoice_type == 2) {
        return this.$Message.warning(`请选择客户`)
      } else {
        this.choiseCompany = false
        if (this.queryFrom.invoice_type == 1) {
          this.$router.push({
            path: '/choiseListTable',
            query: {
              supplier_id: this.choiseCompanyName,
            },
          })
        } else {
          let customer_type, customer_id
          this.choiseList.forEach(item => {
            if (this.choiseCompanyName === item.customer_name) {
              customer_type = item.customer_type
              customer_id = item.customer_id
            }
          })
          this.$router.push({
            path: '/choiseListTableKehu',
            query: {
              customer_id,
              customer_type
            },
          })
        }
      }
    },
    changeNoteFun() {
      if (this.listDataNote == this.changeNote) {
        this.$set(this.listData[this.listDataIndex], 'jujiao', true)
        return
      }
      // this.$http
      //   .put(this.$api.wareHouseReceipt, {
      //     oddnum: this.oddnum,
      //     note: this.changeNote ? this.changeNote : '',
      //   })
      //   .then(res => {
      //     if (res.status) {
      //       this.$Message.success('修改成功')
      //       this.getTableList(this.pages, this.searchObj)
      //     }
      //   })
    },
    clickBeiZhu(index, id, note) {
      this.rowOddnum = id
      this.listDataIndex = index
      this.listDataNote = note
      this.changeNote = JSON.parse(JSON.stringify(note))
      this.$set(this.listData[index], 'jujiao', false)
      this.$set(this.listData[index], 'note', this.listDataNote)
      let data = 'input' + id
      setTimeout(() => {
        this.$refs[data].$el.children.forEach(item => {
          if (item.nodeName == 'INPUT') {
            this.$nextTick(() => {
              document.getElementsByClassName('ivu-input ivu-input-default')[3].focus()
            })
          }
        }, 500)
      })
    },
    // 跳转至新增发票页面
    addNew(title) {
      // 选择客户/供应商
      this.choiseCompany = true
      this.choiseTitle = title
      if (title == '请选择客户') {
        this.choiseList = this.kehuList
      } else {
        this.choiseList = this.gongyingshangList
      }
    },
    // 跳转 编辑页、详情页
    edit(row, readonly) {
      // 进入供应商编辑、详情
      if (this.queryFrom.invoice_type == '1') {
        this.$router.push({
          path: '/editInvoices',
          query: {
            invoice_no: row.oddnum,
            supplier_id: row.supplier_id,
            readonly: readonly,
          },
        })
      }
      // 进入客户编辑、详情
      if (this.queryFrom.invoice_type == '2') {
        this.$router.push({
          path: '/addCustomerInvoices',
          query: {
            invoice_id: row.id,
            readonly: readonly,
          },
        })
      }
    },

    // 反冲
    recoil(id) {
      let data = {
        invoice_id: id,
        invoice_type: Number(this.queryFrom.invoice_type),
      }
      this.$http.put(this.$api.invoiceRecoil, data, true).then(res => {
        if (res.status) {
          // this.$Message.success('反冲成功')
          this.getInvoiceManage()
        }
      })
      this.isConfirm = [false, '']
    },
    // 查询
    query() {
      this.pages.page = 1
      this.getInvoiceManage()
    },
    // 获取发票列表
    async getInvoiceManage() {
      let data = {}
      let result = {}
      Object.assign(data, this.pages, this.queryFrom)
      /**
       * 接口改了
       * 原 供应商、客户发票用另一个接口
       * 现 供应商发票用另一个接口，原接口只查询客户发票
       **/
      this.isLoad = true
      if (this.queryFrom.invoice_type == '1') {
        // 供应商发票
        delete data.invoice_type // 供应商发票不用传发票类型
        result = await this.$http.get(this.$api.supplierInvoice, data, true)
      } else {
        // 客户发票
        result = await this.$http.get(this.$api.invoiceManage, data, true)
      }
      this.isLoad = false
      // 整理数据
      this.listData = result.data.invoice
      this.totleMoney = result.data.total_count
      this.total = result.data.total
      // 获取表单下拉列表
      if (this.queryFrom.invoice_type == '1') {
        this.getSupplierNameList()
      } else {
        this.getCustomerList()
      }
    },
    // 获取供应商下拉列表
    getSupplierNameList() {
      this.$http.get(this.$api.getSupplierNameList).then(res => {
        this.thirdList = res.data
        this.gongyingshangList = res.data
      })
    },
    // 获取客户名称下拉列表
    getCustomerList() {
      this.$http.get(this.$api.customerList).then(res => {
        this.thirdList = res.data
        this.kehuList = res.data
      })
    },
    // 分页改变
    changePage(e) {
      this.pages.page = e
      this.getInvoiceManage()
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        this.queryFrom.billing_time_start = e
        if (e > this.queryFrom.billing_time_end) {
          this.queryFrom.billing_time_end = ''
        }
        this.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.billing_time_start) - 86400000
          },
        }
      } else {
        this.queryFrom.billing_time_end = e
      }
    },
    // 点击内外层标签
    clickNav(index, num) {
      this.queryFrom.invoice_oddnum = ''
      this.queryFrom.name = ''
      this.queryFrom.billing_time_start = ''
      this.queryFrom.billing_time_end = ''
      this.pages.page = 1
      if (num == 1) {
        // 外层标签
        this.queryFrom.status = ''
        this.queryFrom.invoice_type = index
        console.log(this.queryFrom.invoice_type, 'this.queryFrom.invoice_type')
      } else {
        // 内层标签
        this.queryFrom.status = index
      }
      this.getInvoiceManage()
    },
  },
}
</script>

<style scoped lang="less">
.marginRight40 {
  margin-right: 40px;
}
// 反冲确认弹窗
.foot {
  text-align: right;
  margin-top: 40px;
}
.topModal {
  padding-bottom: 56px;
  margin-top: 14px;
  .tipsImg {
    width: 36px;
    height: 36px;
    margin: 0 13px 0 9px;
  }
  .flDiv {
    p:nth-child(1) {
      display: block;
      height: 24px;
      font-size: 18px;
      font-weight: 600;
      color: rgba(51, 51, 51, 1);
      line-height: 24px;
      margin-bottom: 7px;
    }
    p:nth-child(2) {
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }
  }
}

.nav {
  width: 100%;
  height: 42px;
  background-color: #f0f0f0 !important;

  li {
    float: left;
    width: 120px;
    height: 42px;
    line-height: 40px;
    text-align: center;
    font-size: 15px;
    color: #999999;
    background-color: #e2e2e2;
    margin-right: 2px;
    border-radius: 3px 3px 0 0;
  }

  .active {
    background: #fff;
    color: #008cf8;
    font-weight: bold;
  }
}

.page {
  display: flex;
  flex-direction: column;

  .pagePadding {
    flex: 1;

    .nav {
      background-color: #ffffff !important;

      .active {
        border: 1px solid #ededed;
      }
    }

    .table {
      border: 1px solid #e8eaec;
      padding: 20px 25px 30px 25px;
    }
  }
}
.iptWidth {
  width: 100%;
}
</style>
